import React, { useState, useEffect } from "react";
import { fetchVenues, deleteVenue } from "../api/api"; // Import the fetchVenues function
import Loader from "../components/Loader";
import { FaTrashAlt } from "react-icons/fa";

const VenueList = () => {
  const [venues, setVenues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getVenues = async () => {
      try {
        const venueData = await fetchVenues(); // Fetch venues data
        setVenues(venueData); // Set venue data to state
      } catch (error) {
        setError("Failed to load venue details");
        console.error("Error fetching venue details:", error);
      } finally {
        setLoading(false);
      }
    };

    getVenues(); // Call the function to fetch venue data
  }, []);

  const handleDelete = async (venueId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this venue?"
    );
    if (!confirmDelete) return;

    const token = localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth")).token
      : null;

    try {
      await deleteVenue(venueId, token); // Call the deleteVenue function
      setVenues((prevVenues) =>
        prevVenues.filter((v) => v.VenueId !== venueId)
      );
    } catch (error) {
      console.error("Error deleting venue:", error);
      alert("Failed to delete venue. Please try again.");
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div className="text-center p-6">
        <p className="text-red-500 font-semibold">{error}</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg space-y-8">
      <h2 className="text-3xl font-bold text-center text-gray-800">Venue Details</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {venues.map((venue) => (
          <div
            key={venue.VenueId}
            className="bg-gray-50 p-6 rounded-lg shadow-md relative" // Add `relative` here
          >
            {/* Delete Button */}
            <button
              onClick={() => handleDelete(venue.VenueId)}
              className="absolute top-2 right-2 bg-red-100 text-red-500 hover:bg-red-200 rounded-full p-2 shadow transition duration-200"
            >
              <FaTrashAlt size={16} />
            </button>

            {/* Venue Details */}
            <h3 className="text-2xl font-semibold text-gray-700 mb-4">{venue.VenueName}</h3>
            <p className="text-lg text-gray-600 mb-4">{venue.VenueDescription}</p>

            <div className="mt-4">
              <h4 className="text-xl font-medium text-gray-700 mb-2">Address</h4>
              <div className="space-y-1 text-gray-600">
                <p>
                  <strong>City:</strong> {venue?.VenueAddress?.city || "N/A"}
                </p>
                <p>
                  <strong>State:</strong> {venue?.VenueAddress?.state || "N/A"}
                </p>
                <p>
                  <strong>Country:</strong> {venue?.VenueAddress?.country || "N/A"}
                </p>
                <p>
                  <strong>Pincode:</strong> {venue?.VenueAddress?.pincode || "N/A"}
                </p>
                <p>
                  <strong>Landmark:</strong> {venue?.VenueAddress?.landmark || "N/A"}
                </p>
              </div>
            </div>

            {venue.MapLink && (
              <div className="mt-6">
                <h4 className="text-xl font-medium text-gray-700 mb-2">Location</h4>
                <p>
                  <strong>Map Link:</strong>{" "}
                  <a
                    href={venue.MapLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:text-blue-700"
                  >
                    View on Map
                  </a>
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VenueList;
