import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchEventMembers, fetchWaitlist, fetchUserById, fetchTeams } from "../api/api";
import * as XLSX from "xlsx";

const RegistrationDetails = () => {
  const { eventId } = useParams();
  const [tab, setTab] = useState("members");
  const [eventMembers, setEventMembers] = useState([]);
  const [groupedMembers, setGroupedMembers] = useState({});
  const [waitlist, setWaitlist] = useState([]);
  const [userProfiles, setUserProfiles] = useState({});
  const [teams, setTeams] = useState({});
  const [loadingTeams, setLoadingTeams] = useState(false);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [loadingWaitlist, setLoadingWaitlist] = useState(false);
  const [membersError, setMembersError] = useState("");
  const [waitlistError, setWaitlistError] = useState("");
  const [teamsError, setTeamsError] = useState("");

  // Fetch Event Members
  useEffect(() => {
    if (!eventId) {
      setMembersError('Event ID is missing.');
      return;
    }

    const fetchMembers = async () => {
      try {
        setLoadingMembers(true);

        // Fetch members for the specific eventId
        const members = await fetchEventMembers(eventId);

        // Group members by TeamId
        const grouped = members.reduce((acc, member) => {
          acc[member.TeamId] = acc[member.TeamId] || [];
          acc[member.TeamId].push(member);
          return acc;
        }, {});

        setGroupedMembers(grouped);
        setEventMembers(members);
      } catch (error) {
        setMembersError('Failed to fetch event members.');
      } finally {
        setLoadingMembers(false);
      }
    };

    fetchMembers();
  }, [eventId]);
  
  // Fetch User Profiles for Members
  useEffect(() => {
    const fetchProfiles = async () => {
      const profiles = {};
      for (const member of eventMembers) {
        if (!userProfiles[member.UserId]) {
          try {
            const profile = await fetchUserById(member.UserId);
            profiles[member.UserId] = profile;
          } catch (error) {
            console.error(`Failed to fetch profile for UserId: ${member.UserId}`);
          }
        }
      }
      setUserProfiles((prev) => ({ ...prev, ...profiles }));
    };

    fetchProfiles();
  }, [eventMembers]);

  // Fetch Waitlist
  useEffect(() => {
    const fetchWaitlistData = async () => {
      try {
        setLoadingWaitlist(true);
  
        const waitlistData = await fetchWaitlist(eventId);
  
        // Check if the API response contains a message indicating no entries
        if (waitlistData.message) {
          setWaitlist([]); // Set an empty array for waitlist
          setWaitlistError(waitlistData.message); // Display the message
        } else {
          setWaitlist(waitlistData); // Set the fetched waitlist data
          setWaitlistError(null); // Clear any previous errors
        }
      } catch (error) {
        setWaitlist([]); // Clear waitlist on error
        setWaitlistError(
          error.response?.status === 404
            ? "No waitlist found for this event."
            : "Failed to fetch waitlist."
        ); // Set specific error message based on response status
      } finally {
        setLoadingWaitlist(false);
      }
    };
  
    fetchWaitlistData();
  }, [eventId]);
  

  // Fetch Teams
  useEffect(() => {
    const loadTeams = async () => {
      try {
        setLoadingTeams(true);
        const teamsData = await fetchTeams();
        const teamsMap = teamsData.reduce((map, team) => {
          map[team.TeamId] = team.TeamName;
          return map;
        }, {});
        setTeams(teamsMap);
      } catch (error) {
        setTeamsError("Failed to fetch teams.");
      } finally {
        setLoadingTeams(false);
      }
    };

    loadTeams();
  }, []);

  const handleExportMembers = () => {
    const data = [];
    Object.keys(groupedMembers).forEach((teamId) => {
      groupedMembers[teamId].forEach((member) => {
        const profile = userProfiles[member.UserId];
        const teamName = teams[teamId] || "Unknown Team";
        if (profile) {
          data.push({
            TeamName: teamName,
            OrderID: member.OrderId,
            UserId: member.UserId,
            Name: `${profile.FirstName} ${profile.LastName}`,
            Mobile: `${profile.MobileNumberExt}${profile.MobileNumber}`,
            Email: profile.EmailAddress,
          });
        }
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `Event Members`);
    XLSX.writeFile(workbook, `EventMembers.xlsx`);
  };

  // Export Waitlist to Excel
  const handleExportWaitlist = () => {
    const data = waitlist.map((item) => {
      const user = userProfiles[item.UserId];
      const teamName = teams[item.TeamId] || "Unknown Team";
      return {
        Name: user ? `${user.FirstName} ${user.LastName}` : "N/A",
        Mobile: user ? `${user.MobileExt}${user.mobile}` : "N/A",
        Email: user ? user.EmailAddress : "N/A",
        Team: teamName,
        OrderAmount: item.OrderAmount,
        OrderStatus: item.OrderStatus,
        CreatedAt: new Date(item.CreatedAtTimeStamp).toLocaleString(),
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `Waitlist`);
    XLSX.writeFile(workbook, `EventWaitlist.xlsx`);
  };

  const renderTabContent = () => {
    if (tab === "members") {
      if (loadingMembers || loadingTeams) {
        return <div>Loading event members...</div>;
      }
  
      if (membersError || teamsError) {
        return <div className="text-red-500">{membersError || teamsError}</div>;
      }
  
      if (!eventMembers || eventMembers.length === 0) {
        return <div>No event members found for this event.</div>;
      }
  
      return (
        <div>
          <button
            onClick={handleExportMembers}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 mb-4"
          >
            Export to Excel
          </button>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white rounded-lg shadow-lg overflow-hidden">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Team Name</th>
                  <th className="py-3 px-6 text-left">Order ID</th>
                  <th className="py-3 px-6 text-left">User ID</th>
                  <th className="py-3 px-6 text-left">Name</th>
                  <th className="py-3 px-6 text-left">Mobile</th>
                  <th className="py-3 px-6 text-left">Email</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {Object.keys(groupedMembers).map((teamId) => {
                  const teamMembers = groupedMembers[teamId];
                  const teamName = teams[teamId] || "Unknown Team";
  
                  return (
                    <React.Fragment key={teamId}>
                      {/* Team Row */}
                      <tr className="bg-gray-100 font-semibold">
                        <td className="py-3 px-6 text-left" colSpan="6">
                          Team: {teamName}
                        </td>
                      </tr>
  
                      {/* Member Rows */}
                      {teamMembers.map((member) => {
                        const profile = userProfiles[member.UserId];
                        return (
                          <tr
                            key={member.UserId}
                            className="border-b border-gray-200 hover:bg-gray-100"
                          >
                            <td className="py-3 px-6 text-left whitespace-nowrap">
                              {teamName}
                            </td>
                            <td className="py-3 px-6 text-left whitespace-nowrap">
                              {member.OrderId}
                            </td>
                            <td className="py-3 px-6 text-left whitespace-nowrap">
                              {member.UserId}
                            </td>
                            <td className="py-3 px-6 text-left font-medium">
                              {profile
                                ? `${profile.FirstName} ${profile.LastName}`
                                : "Loading..."}
                            </td>
                            <td className="py-3 px-6 text-left">
                              {profile
                                ? `${profile.MobileNumberExt}${profile.MobileNumber}`
                                : "Loading..."}
                            </td>
                            <td className="py-3 px-6 text-left">
                              {profile ? profile.EmailAddress : "Loading..."}
                            </td>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  
    // Waitlist Tab Content
    if (tab === "waitlist") {
      if (loadingWaitlist) {
        return <div>Loading waitlist...</div>;
      }
  
      if (waitlistError) {
        return <div className="text-red-500">{waitlistError}</div>;
      }
  
      if (!waitlist || waitlist.length === 0) {
        return <div>No waitlist found for this event.</div>;
      }
  
      return (
        <div className="overflow-x-auto">
          <button
            onClick={handleExportWaitlist}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 mb-4"
          >
            Export to Excel
          </button>
          <table className="min-w-full bg-white rounded-lg shadow-lg overflow-hidden">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">Name</th>
                <th className="py-3 px-6 text-left">Mobile</th>
                <th className="py-3 px-6 text-left">Email</th>
                <th className="py-3 px-6 text-left">Team</th>
                <th className="py-3 px-6 text-left">Order Amount</th>
                <th className="py-3 px-6 text-left">Order Status</th>
                <th className="py-3 px-6 text-left">Created At</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {waitlist.map((item, index) => {
                const user = userProfiles[item.UserId];
                const teamName = teams[item.TeamId] || "N/A";
                return (
                  <tr
                    key={index}
                    className="border-b border-gray-200 hover:bg-gray-100"
                  >
                    <td className="py-3 px-6 text-left whitespace-nowrap font-medium">
                      {user ? `${user.FirstName} ${user.LastName}` : "N/A"}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {user ? `${user.MobileExt}${user.mobile}` : "N/A"}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {user ? user.EmailAddress : "N/A"}
                    </td>
                    <td className="py-3 px-6 text-left">{teamName}</td>
                    <td className="py-3 px-6 text-left">${item.OrderAmount}</td>
                    <td className="py-3 px-6 text-left text-[#ff0000] font-normal">
                      {item.OrderStatus}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {new Date(item.CreatedAtTimeStamp).toLocaleString()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
  };

  return (
    <div className="p-4 bg-white shadow rounded">
      <h1 className="text-xl font-bold mb-4">Registration Details</h1>
      <div className="flex border-b border-gray-200">
        <button
          className={`py-2 px-4 ${tab === "members" ? "border-b-2 border-blue-500 text-blue-500" : ""}`}
          onClick={() => setTab("members")}
        >
          Event Members
        </button>
        <button
          className={`py-2 px-4 ${tab === "waitlist" ? "border-b-2 border-blue-500 text-blue-500" : ""}`}
          onClick={() => setTab("waitlist")}
        >
          Waitlist
        </button>
      </div>
      <div className="mt-4">{renderTabContent()}</div>
    </div>
  );
};

export default RegistrationDetails;
