import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom"; // Import navigate for programmatic navigation
import { fetchEvents, updateEvent } from "../api/api";
import EventDetailsModal from "../components/EventDetailsModal";
import EditEventForm from "../components/EditEventForm";
import { FaPlus } from "react-icons/fa"; // Importing the plus icon from react-icons
import Loader from "../components/Loader";

const DashboardHome = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [editingEvent, setEditingEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate(); // useNavigate hook for navigation

  // Fetch events when the component mounts
  useEffect(() => {
    const loadEvents = async () => {
      try {
        const response = await fetchEvents();
        if (response && Array.isArray(response.events)) {
          setEvents(response.events);
        } else {
          console.error("Invalid events data received");
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    loadEvents();
  }, []);

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  // Handle saving updates to an event
  const handleEditSave = async (updatedEvent) => {
    try {
      const updatedEventData = await updateEvent(
        updatedEvent.EventId, // EventId is in the URL
        updatedEvent
      );
      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.EventId === updatedEventData.EventId ? updatedEventData : event
        )
      );
      setEditingEvent(null);
      setShowConfirmation(true);
      setTimeout(() => setShowConfirmation(false), 3000);
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };

  // Handle the edit button click
  const handleEditClick = (event) => {
    setEditingEvent(event); // Set the full event object, including EventId
  };

  // Filter ongoing events
  const ongoingEvents = events.filter(
    (event) => new Date(event.event_schedule.EndDate) >= new Date()
  );

  // Filter upcoming events
  const upcomingEvents = events.filter(
    (event) => new Date(event.event_schedule.StartDate) > new Date()
  );

  // Navigate to event details page when event card is clicked
  const handleCardClick = (eventId) => {
    // Using navigate to redirect programmatically
    navigate(`/events/details/${eventId}`);
  };

  return (
    <div className="bg-gray-100 dashboard-container grid grid-cols-1 sm:grid-cols-1 md:grid-cols-12 lg:grid-cols-12 gap-4 p-4 h-fit">
      {/* Left Side - Placeholder Sections */}
      <section className="col-span-12 sm:col-span-12 md:col-span-8 grid grid-rows-1 gap-4 h-fit">
        {/* Placeholder Section 1 with reduced height */}
        <div className="bg-gray-100 p-4 rounded shadow-lg h-fit">
          <h2 className="text-xl font-semibold mb-2 text-center text-gray-800">
            Welcome
          </h2>
          <p className="text-sm text-gray-600 text-center">
            This is the admin dashboard for NetGala. Manage and edit events
            easily from this interface.
          </p>
        </div>

        {/* Ongoing Events Section */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg overflow-auto h-auto mt-4">
          <h2 className="text-xl font-semibold mb-4 text-gray-800">
            Ongoing Events
          </h2>
          {loading ? (
            <p className="text-center text-gray-700">Loading...</p>
          ) : ongoingEvents.length ? (
            ongoingEvents.map((event) => (
              <div
                key={event.EventId}
                className="block p-6 mb-4 border rounded-lg shadow-lg bg-white hover:shadow-2xl transition-shadow cursor-pointer"
                onClick={() => handleCardClick(event.EventId)} // Navigate to the event details page
              >
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-semibold text-gray-800">
                    {event.EventTitle}
                  </h3>
                  <button
                    className="text-[#512D9A] py-1 px-3 border-2 border-[#F2F2F2] rounded hover:shadow-sm hover:font-medium"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent the card click event from triggering
                      handleEditClick(event); // Set the event to be edited
                    }}
                  >
                    Edit
                  </button>
                </div>
                <p className="text-sm text-gray-600">
                  {event.EventDescription.length > 90
                    ? `${event.EventDescription.slice(0, 90)}...`
                    : event.EventDescription}
                </p>
                <div className="mt-2 text-sm text-gray-500">
                  <p>
                    <strong>Type:</strong> {event.EventType}
                  </p>
                  <p>
                    <strong>Start Date:</strong>{" "}
                    {new Date(
                      event.event_schedule.StartDate
                    ).toLocaleDateString()}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-700">
              No ongoing events available
            </p>
          )}
        </div>
      </section>

      {/* Right Side - Events Listing */}
      <section className="col-span-12 sm:col-span-12 md:col-span-4 grid grid-rows-1 gap-4">
        {/* Upcoming Events Section */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-lg overflow-auto h-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-gray-800">
              Upcoming Events
            </h2>
            {/* Create Event Button */}
            <Link to="/events/create">
              <button className="bg-[#2D1066] text-white p-2 rounded-full hover:bg-[#6CB52D]">
                <FaPlus size={18} />
              </button>
            </Link>
          </div>
          {loading ? (
            <p className="text-center text-gray-700">Loading...</p>
          ) : upcomingEvents.length ? (
            upcomingEvents.map((event) => (
              <div
                key={event.EventId}
                className="block mb-4 p-6 border rounded-lg shadow-lg bg-white hover:shadow-2xl transition-shadow cursor-pointer"
                onClick={() => handleCardClick(event.EventId)} // Navigate to the event details page
              >
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-semibold text-gray-800">
                    {event.EventTitle}
                  </h3>
                  <button
                    className="text-[#512D9A] py-1 px-3 border-2 border-[#F2F2F2] rounded hover:shadow-sm hover:font-medium"
                    onClick={(e) => {
                      e.preventDefault();
                      handleEditClick(event); // Pass the full event object here
                    }}
                  >
                    Edit
                  </button>
                </div>
                <p className="text-sm text-gray-600">
                  {event.EventDescription.length > 90
                    ? `${event.EventDescription.slice(0, 90)}...`
                    : event.EventDescription}
                </p>
                <div className="mt-2 text-sm text-gray-500">
                  <p>
                    <strong>Type:</strong> {event.EventType}
                  </p>
                  <p>
                    <strong>Start Date:</strong>{" "}
                    {new Date(
                      event.event_schedule.StartDate
                    ).toLocaleDateString()}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-700">
              No upcoming events available
            </p>
          )}
        </div>
      </section>

      {/* Event Details Modal */}
      {selectedEvent && (
        <EventDetailsModal
          event={selectedEvent}
          onClose={() => setSelectedEvent(null)} // Close the modal
        />
      )}

      {/* Edit Event Form Overlay */}
      {editingEvent && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <EditEventForm
            event={editingEvent} // Full event object passed here
            onSave={handleEditSave} // Save the updated event
            onCancel={() => setEditingEvent(null)} // Cancel the edit
          />
        </div>
      )}

      {/* Confirmation Message */}
      {showConfirmation && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white p-3 rounded-lg shadow-md">
          <p>Changes saved successfully!</p>
        </div>
      )}
    </div>
  );
};

export default DashboardHome;
