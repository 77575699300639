import React, { useState } from 'react';
import { createRegion } from '../api/api'; // Import the API function

const CreateRegion = () => {
  const [regionName, setRegionName] = useState('');
  const [isActive, setIsActive] = useState(true); // Default to active
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleRegionSubmit = async (e) => {
    e.preventDefault();
    const regionData = { RegionName: regionName, IsActive: isActive };

    try {
      await createRegion(regionData); // Call the API to create the region
      setSuccessMessage('Region created successfully!');
      setRegionName('');
      setIsActive(true); // Reset after success
      setErrorMessage('');
      setTimeout(() => setSuccessMessage(''), 4000); // Hide success message after 4 seconds
    } catch (error) {
      setErrorMessage('Failed to create region. Please try again.');
    }
  };

  return (
    <div className="space-y-6 max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-2xl font-semibold text-center">Create Region</h2>

      {/* Success Notification */}
      {successMessage && (
        <div className="bg-green-500 text-white p-4 rounded-md shadow-lg mb-4 flex items-center justify-between">
          <div className="flex items-center">
            <span className="material-icons text-xl">check_circle</span>
            <span className="ml-2">{successMessage}</span>
          </div>
          <button
            className="text-white font-bold ml-4"
            onClick={() => setSuccessMessage('')} // Manually close the notification
          >
            &times;
          </button>
        </div>
      )}

      {/* Error Message */}
      {errorMessage && (
        <p className="text-red-500 text-center">{errorMessage}</p>
      )}

      {/* Region Creation Form */}
      <form onSubmit={handleRegionSubmit} className="space-y-4">
        <div className="flex flex-col">
          <label htmlFor="regionName" className="text-lg font-semibold">Region Name</label>
          <input
            type="text"
            id="regionName"
            name="regionName"
            value={regionName}
            onChange={(e) => setRegionName(e.target.value)}
            className="border px-4 py-2 mt-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter region name"
            required
          />
        </div>

        <div className="flex items-center space-x-4">
          <label htmlFor="isActive" className="text-lg font-semibold">Is Active</label>
          <input
            type="checkbox"
            id="isActive"
            name="isActive"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
            className="w-4 h-4"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200"
        >
          Create Region
        </button>
      </form>
    </div>
  );
};

export default CreateRegion;
