import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getUserById, getTransactions } from "../api/api";

const UserDetails = () => {
  const { userId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const user = await getUserById(userId);
        setUserDetails(user);
      } catch (err) {
        setError("Failed to fetch user details.");
        console.error("Error fetching user details:", err);
      }
    };

    const fetchUserTransactions = async () => {
      try {
        const transactionData = await getTransactions(userId, 20, 0);
        setTransactions(transactionData?.transactions || []);
      } catch (err) {
        setError("Failed to fetch transactions.");
        console.error("Error fetching transactions:", err);
      }
    };

    setLoading(true);
    setError(null);
    Promise.all([fetchUserDetails(), fetchUserTransactions()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [userId]);

  if (loading) return <p className="text-gray-500">Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">User Details</h2>

      {userDetails && (
        <div className="mb-6">
          <p>
            <strong>Name:</strong> {userDetails.FirstName} {userDetails.LastName}
          </p>
          <p>
            <strong>Mobile:</strong> {userDetails.MobileNumber}
          </p>
          <p>
            <strong>Email:</strong> {userDetails.EmailAddress}
          </p>
        </div>
      )}

      <h3 className="text-xl font-bold mb-4">Transactions</h3>
      {transactions.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 px-4 py-2">Transaction ID</th>
                <th className="border border-gray-300 px-4 py-2">Date & Time</th>
                <th className="border border-gray-300 px-4 py-2">Status</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((txn) => (
                <tr key={txn.transaction_id}>
                  <td className="border border-gray-300 px-4 py-2">
                    {txn.transaction_id}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {new Date(txn.timestamp).toLocaleString()}
                  </td>
                  <td
                    className={`border border-gray-300 px-4 py-2 ${
                      txn.status === "Success"
                        ? "text-green-500 font-medium"
                        : "text-yellow-500 font-medium"
                    }`}
                  >
                    {txn.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-gray-500">No transactions found.</p>
      )}
    </div>
  );
};

export default UserDetails;
