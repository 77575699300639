import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import navigate from react-router-dom
import { useAuthContext } from "../AuthContext"; // Import your custom useAuthContext
import { loginOTP, verifyOTP } from "../api/api"; // Import correct functions


const LoginPage = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91"); // Default to country code +91
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { login } = useAuthContext(); // Use login function from AuthContext
  const { setAccessToken } = useAuthContext();

  // Handle Mobile Number Submit - Send OTP
  const handleMobileNumberSubmit = async () => {
    try {
      console.log("Sending OTP...");

      // Use loginOTP function to send OTP
      await loginOTP({ mobile_number: mobileNumber, country_code: countryCode });

      console.log("OTP Sent!");
      setIsOtpSent(true);
      setErrorMessage("");
    } catch (error) {
      console.error("Error sending OTP:", error);
      setErrorMessage("Failed to send OTP. Please try again.");
    }
  };

  // Handle OTP Submit - Verify OTP and login
  const handleOtpSubmit = async () => {
    try {
      console.log("Verifying OTP...");
      
      // Verify OTP and get response
      const response = await verifyOTP({ mobile_number: mobileNumber, otp });
  
      console.log("OTP Verified!");
  
      const { AccessToken } = response;
      setAccessToken(AccessToken);
  
      // Log in with token and 24-hour session duration
      login({ token: AccessToken }, 24);
  
      // Redirect to the dashboard
      navigate("/");
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setErrorMessage("Invalid OTP. Please try again.");
    }
  };

  return (
    <div className="min-h-screen flex justify-center items-center bg-gray-100">
      <div className="bg-white rounded-lg shadow-xl p-8 w-full sm:w-96 md:w-96">
        <h2 className="text-3xl font-semibold text-center text-[#2D1066] mb-6">Login</h2>

        <div className="space-y-4">
          {!isOtpSent ? (
            <div className="space-y-4">
              <div className="flex flex-col">
                <label htmlFor="mobileNumber" className="text-lg font-semibold text-gray-700">
                  Mobile Number
                </label>
                <div className="flex items-center border-b-2 mt-2">
                  <input
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    className="flex-1 px-2 py-2 outline-none border-transparent focus:ring-0"
                    placeholder="Enter mobile number"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label htmlFor="countryCode" className="text-lg font-semibold text-gray-700">
                  Country Code
                </label>
                <select
                  id="countryCode"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                  className="px-2 py-2 mt-2 border-b-2"
                >
                  <option value="+91">+91 (India)</option>
                  <option value="+1">+1 (USA)</option>
                  <option value="+44">+44 (UK)</option>
                  <option value="+61">+61 (Australia)</option>
                  {/* Add more country codes as needed */}
                </select>
              </div>

              <button
                onClick={handleMobileNumberSubmit}
                className="w-full bg-[#2D1066] text-white py-2 rounded-md hover:bg-[#6CB52D] transition"
              >
                Send OTP
              </button>
            </div>
          ) : (
            <div className="space-y-4">
              <div className="flex flex-col">
                <label htmlFor="otp" className="text-lg font-semibold text-gray-700">
                  Enter OTP
                </label>
                <div className="flex items-center border-b-2 mt-2">
                  <input
                    type="text"
                    id="otp"
                    name="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="flex-1 px-2 py-2 outline-none border-transparent focus:ring-0"
                    placeholder="Enter OTP"
                  />
                </div>
              </div>

              <button
                onClick={handleOtpSubmit}
                className="w-full bg-[#2D1066] text-white py-2 rounded-md hover:bg-[#6CB52D] transition"
              >
                Verify OTP
              </button>
            </div>
          )}
        </div>

        {errorMessage && (
          <p className="mt-4 text-center text-red-500 font-semibold">{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
