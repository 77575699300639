import React, { useState, useEffect } from "react";
import {
  fetchVenues,
  fetchSports,
  fetchSponsors,
  uploadEventImage,
  downloadFile,
} from "../api/api";
import CropperEventImage from "../components/CropperEventImage";
import { FaTimes } from "react-icons/fa";

const EditEventForm = ({ event, onSave, onCancel }) => {
  const initialFormData = {
    EventTitle: event?.EventTitle || "",
    EventDescription: event?.EventDescription || "",
    EventType: event?.EventType || "League",
    EventImageFileId: event?.EventImageFileId || [],
    EventImages: event?.EventImages || [],
    SportId: event?.SportId || "",
    TeamSize: event?.TeamSize || 0,
    IsPartnerAllowed: event?.IsPartnerAllowed || true,
    TotalTeams: event?.TotalTeams || 0,
    PriceCurrency: event?.PriceCurrency || "INR",
    Price: event?.Price || 0,
    PriceCredits: event?.PriceCredits || 0,
    isPaid: event?.isPaid || true,
    FAQ: Array.isArray(event?.FAQ) ? event.FAQ : [],
    Venue: event?.Venue || {
      VenueName: "",
      VenueAddress: {
        country: "",
        state: "",
        city: "",
        pincode: "",
        landmark: "",
      },
      VenueRegionId: "",
      MapLink: "",
      VenueDescription: "",
    },
    EventSchedule: event?.EventSchedule || {
      StartDate: new Date().toISOString(),
      EndDate: new Date().toISOString(),
      StartTimeStamp: new Date().toISOString(),
      EndTimeStamp: new Date().toISOString(),
      RegistrationStartDate: new Date().toISOString(),
      RegistrationEndDate: new Date().toISOString(),
      RegistrationStartTimeStamp: new Date().toISOString(),
      RegistrationEndTimeStamp: new Date().toISOString(),
      IsRecurring: false,
      RecurrenceRule: "",
      RecurrenceInterval: 0,
      RecurrenceEndDate: new Date().toISOString(),
      RecurrenceDaysOfWeek: [],
    },
    SponsorIds: event?.sponsors?.map((sponsor) => sponsor.SponsorId) || [],
    IsActive: event?.IsActive || true,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [venues, setVenues] = useState([]);
  const [sports, setSports] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [showCropper, setShowCropper] = useState(false);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch related data for Venues, Sports, and Sponsors
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedVenues = await fetchVenues();
        const fetchedSports = await fetchSports();
        const fetchedSponsors = await fetchSponsors();
        setVenues(fetchedVenues);
        setSports(fetchedSports);
        setSponsors(fetchedSponsors);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchPreviews = async () => {
      if (event?.EventImageFileId?.length > 0) {
        try {
          const previewPromises = event.EventImageFileId.map((fileId) =>
            downloadFile(fileId)
          );
          const previews = await Promise.all(previewPromises);
          setImagePreviews(previews);
        } catch (error) {
          console.error("Error fetching image previews:", error);
        }
      }
    };

    fetchPreviews();
  }, [event]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle date/time changes for EventSchedule
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      EventSchedule: {
        ...prevData.EventSchedule,
        [name]: value,
      },
    }));
  };

  // Handle sponsor selection
  const handleSponsorChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      SponsorIds: checked
        ? [...prevData.SponsorIds, value]
        : prevData.SponsorIds.filter((id) => id !== value),
    }));
  };

  // Handle venue change
  const handleVenueChange = (e) => {
    const { value } = e.target;
    const selectedVenue = venues.find((venue) => venue.VenueId === value);

    setFormData((prevData) => ({
      ...prevData,
      Venue: selectedVenue || prevData.Venue,
    }));
  };

  //Handle Image upload with cropper
  const handleImageCrop = async (blob) => {
    const formData = new FormData();
    formData.append("data", blob);

    try {
      const accessToken = localStorage.getItem("auth")
        ? JSON.parse(localStorage.getItem("auth")).token
        : null;

      if (!accessToken) {
        console.error("Access token is missing. Please log in.");
        return;
      }

      const response = await uploadEventImage(formData, accessToken);

      if (response && response.FileId) {
        const preview = await downloadFile(response.FileId);

        if (!preview) {
          console.error("Preview URL could not be generated.");
          return;
        }

        setFormData((prevData) => ({
          ...prevData,
          EventImages: [
            ...prevData.EventImages,
            { FileId: response.FileId, Tags: [], preview },
          ],
          EventImageFileId: [...prevData.EventImageFileId, response.FileId],
        }));
      } else {
        console.error("Image upload failed:", response);
      }
    } catch (error) {
      console.error("Error in image upload or preview generation:", error);
    } finally {
      setShowCropper(false);
    }
  };

  // Handle FAQ changes
  const handleFAQChange = (index, e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedFAQs = [...prevData.FAQ];
      updatedFAQs[index] = { ...updatedFAQs[index], [name]: value };
      return { ...prevData, FAQ: updatedFAQs };
    });
  };

  const addFAQ = () => {
    setFormData((prevData) => ({
      ...prevData,
      FAQ: [...prevData.FAQ, { question: "", answer: "" }],
    }));
  };

  const removeFAQ = (index) => {
    setFormData((prevData) => {
      const updatedFAQs = [...prevData.FAQ];
      updatedFAQs.splice(index, 1);
      return { ...prevData, FAQ: updatedFAQs };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Loaded event:", event);

    if (!event?.EventId) {
      console.error("EventId is missing!");
      return;
    }

    const payload = {
      ...formData,
      FAQ: formData.FAQ.map((faq) => ({
        question: faq.question || "",
        answer: faq.answer || "",
      })),
    };
    console.log("Submitting updated event:", payload);
    onSave(event.EventId, payload);
  };

  if (loading) {
    return <p>Loading data...</p>;
  }

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex justify-center items-center z-50">
      <div className="edit-event-form bg-white p-6 rounded shadow-lg w-full sm:w-10/12 md:w-8/12 lg:w-6/12 max-h-[80vh] overflow-y-auto">
        <button
          type="button"
          onClick={onCancel}
          className="absolute top-2 right-2 text-2xl text-[#333333] hover:text-[#CF1909]"
        >
          <FaTimes />
        </button>

        <h2 className="text-3xl font-bold mb-6 text-center text-[#2D1066]">
          Edit Event
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Event Title */}
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">
              Event Title
            </label>
            <input
              type="text"
              name="EventTitle"
              value={formData.EventTitle || ""}
              onChange={handleChange}
              className="w-full p-3 border rounded"
              required
            />
          </div>

          {/* Event Description */}
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">
              Event Description
            </label>
            <textarea
              name="EventDescription"
              value={formData.EventDescription || ""}
              onChange={handleChange}
              className="w-full p-3 border rounded"
              required
            />
          </div>

          {/* Event Type */}
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">
              Event Type
            </label>
            <select
              name="EventType"
              value={formData.EventType || "League"}
              onChange={handleChange}
              className="w-full p-3 border rounded"
            >
              <option value="League">League</option>
              <option value="Tournament">Tournament</option>
              <option value="Bootcamp">Bootcamp</option>
            </select>
          </div>

          {/* Event Images */}
          {/* Event Images */}
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">
              Event Images
            </label>
            {formData.EventImages.length > 0 ? (
              formData.EventImages.map((image, index) => (
                <div key={index} className="flex items-center mt-2">
                  {/* Dynamically show image preview */}
                  <img
                    src={image.preview || ""}
                    alt={`Event image ${index + 1}`}
                    className="w-20 h-20 object-cover rounded-lg mr-2"
                  />
                  <span>{image.Tags.join(", ")}</span>
                  <button
                    type="button"
                    className="ml-4 text-red-500"
                    onClick={() =>
                      setFormData((prevData) => ({
                        ...prevData,
                        EventImages: prevData.EventImages.filter(
                          (_, i) => i !== index
                        ),
                        EventImageFileId: prevData.EventImageFileId.filter(
                          (id) => id !== image.FileId
                        ),
                      }))
                    }
                  >
                    Remove
                  </button>
                </div>
              ))
            ) : (
              <p>No images uploaded</p>
            )}
            <button
              type="button"
              onClick={() => setShowCropper(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
            >
              Add New Image
            </button>
            {showCropper && (
              <CropperEventImage
                onCropComplete={handleImageCrop}
                onClose={() => setShowCropper(false)}
              />
            )}
          </div>

          {/* Sport */}
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">Sport</label>
            <select
              name="SportId"
              value={formData.SportId || ""}
              onChange={handleChange}
              className="w-full p-3 border rounded"
              required
            >
              <option value="">Select Sport</option>
              {sports.map((sport) => (
                <option key={sport.SportId} value={sport.SportId}>
                  {sport.SportType}
                </option>
              ))}
            </select>
          </div>

          {/* Team Size */}
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">
              Team Size
            </label>
            <input
              type="number"
              name="TeamSize"
              value={formData.TeamSize || ""}
              onChange={handleChange}
              className="w-full p-3 border rounded"
              min="1"
            />
          </div>

          {/* Is Partner Allowed */}
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">
              Is Partner Allowed
            </label>
            <input
              type="checkbox"
              name="IsPartnerAllowed"
              checked={formData.IsPartnerAllowed || false}
              onChange={handleChange}
              className="mr-2"
            />
          </div>

          {/* Total Teams */}
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">
              Total Teams
            </label>
            <input
              type="number"
              name="TotalTeams"
              value={formData.TotalTeams || ""}
              onChange={handleChange}
              className="w-full p-3 border rounded"
              min="1"
            />
          </div>

          {/* Price */}
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">
              Price (INR)
            </label>
            <input
              type="number"
              name="Price"
              value={formData.Price || ""}
              onChange={handleChange}
              className="w-full p-3 border rounded"
              min="0"
            />
          </div>

          {/* Price Credits */}
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">
              Price Credits
            </label>
            <input
              type="number"
              name="PriceCredits"
              value={formData.PriceCredits || ""}
              onChange={handleChange}
              className="w-full p-3 border rounded"
              min="0"
            />
          </div>

          {/* Sponsors */}
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">Sponsors</label>
            <div className="flex flex-wrap gap-4">
              {sponsors.map((sponsor) => (
                <div
                  key={sponsor.SponsorId}
                  className="flex items-center gap-2"
                >
                  <input
                    type="checkbox"
                    id={`sponsor-${sponsor.SponsorId}`}
                    name="SponsorIds"
                    value={sponsor.SponsorId}
                    checked={formData.SponsorIds.includes(sponsor.SponsorId)} // Check against formData
                    onChange={handleSponsorChange}
                    className="h-5 w-5"
                  />
                  <label
                    htmlFor={`sponsor-${sponsor.SponsorId}`}
                    className="text-gray-700"
                  >
                    {sponsor.SponsorName}
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/*Faqs*/}
          <div className="mb-6">
            <label className="block text-lg font-semibold mb-2">FAQs</label>
            {Array.isArray(formData.FAQ) && formData.FAQ.length > 0 ? (
              formData.FAQ.map((faq, index) => (
                <div
                  key={index}
                  className="mb-4 border p-4 rounded bg-gray-100"
                >
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-sm font-semibold text-gray-700">
                      FAQ {index + 1}
                    </h3>
                    <button
                      type="button"
                      onClick={() => removeFAQ(index)} // Remove FAQ on button click
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  </div>
                  <input
                    type="text"
                    name="question"
                    placeholder="Question"
                    value={faq.question || ""}
                    onChange={(e) => handleFAQChange(index, e)}
                    className="w-full p-2 border border-gray-300 rounded mb-2"
                  />
                  <textarea
                    name="answer"
                    placeholder="Answer"
                    value={faq.answer || ""}
                    onChange={(e) => handleFAQChange(index, e)}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
              ))
            ) : (
              <p className="text-gray-500">No FAQs available.</p>
            )}
            <button
              type="button"
              onClick={addFAQ} // Add a new FAQ
              className="bg-green-500 text-white px-4 py-2 rounded mt-4"
            >
              Add FAQ
            </button>
          </div>

          {/* Recurrence Details */}
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">
              Is Recurring
            </label>
            <input
              type="checkbox"
              name="IsRecurring"
              checked={formData.EventSchedule.IsRecurring || false}
              onChange={handleChange}
              className="mr-2"
            />
          </div>

          {/* Recurrence Rule */}
          {formData.EventSchedule.IsRecurring && (
            <>
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2">
                  Recurrence Rule
                </label>
                <select
                  name="RecurrenceRule"
                  value={formData.EventSchedule.RecurrenceRule || ""}
                  onChange={handleChange}
                  className="w-full p-3 border rounded"
                >
                  <option value="">Select Recurrence Rule</option>
                  <option value="DAILY">Daily</option>
                  <option value="WEEKLY">Weekly</option>
                  <option value="MONTHLY">Monthly</option>
                </select>
              </div>
              {/* Recurrence Interval */}
              <div className="mb-4">
                <label className="block text-lg font-semibold mb-2">
                  Recurrence Interval
                </label>
                <input
                  type="number"
                  name="RecurrenceInterval"
                  value={formData.EventSchedule.RecurrenceInterval || 0}
                  onChange={handleChange}
                  className="w-full p-3 border rounded"
                />
              </div>
            </>
          )}

          {/* Submit */}
          <div className="text-right">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEventForm;
