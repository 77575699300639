import React, { useState } from "react";
import { uploadEventImage, createSponsor } from "../api/api";
import { useNavigate } from "react-router-dom";
import CropperSponsorImage from "../components/CropperSponsorImage";
import ToastNotification from "../components/ToastNotification";

const CreateSponsor = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    sponsor_type: "default",
    SponsorImages: [], // Array to hold multiple images
  });
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  // Access token from localStorage
  const token = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")).token : null;

  // Handle adding cropped image and uploading it immediately
  const handleAddCroppedImage = async (blob, tag) => {
    await handleImageCrop(blob, tag);
  };

  // Function to handle image cropping and upload
  const handleImageCrop = async (blob, tag) => {
    const formData = new FormData();
    formData.append("data", blob);
    formData.append("alt_text", tag);

    try {
      const response = await uploadEventImage(formData, token);
      if (response && response.FileId) {
        setFormData((prevData) => ({
          ...prevData,
          SponsorImages: [
            ...prevData.SponsorImages,
            { ImageId: response.FileId, Tags: tag },
          ],
        }));
        setToast({ message: "Image uploaded successfully!", type: "success" });
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setToast({ message: "Image upload failed!", type: "error" });
    } finally {
      setShowCropper(false);
    }
  };

  // Function to preview an image
  const handlePreviewImage = (fileId) => {
    const image = formData.SponsorImages.find((img) => img.ImageId === fileId);
    if (image) {
      setPreviewImage(image.ImageId); // Use the ImageId as the image source
    }
  };

  // Function to remove an image
  const handleRemoveImage = (fileId) => {
    setFormData((prevData) => ({
      ...prevData,
      SponsorImages: prevData.SponsorImages.filter((image) => image.ImageId !== fileId),
    }));
    setToast({ message: "Image removed successfully!", type: "success" });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setToast(null);

    if (!token) {
      setToast({ message: "Access token is missing/expired. Please log in.", type: "error" });
      setLoading(false);
      return;
    }

    if (formData.SponsorImages.length === 0) {
      setToast({ message: "At least one image is required!", type: "error" });
      setLoading(false);
      return;
    }

    try {
      await createSponsor(formData, token);
      console.log("Sponsor data",formData)
      setToast({ message: "Sponsor created successfully!", type: "success" });
      navigate("/sponsors");
    } catch (err) {
      console.error("Error creating sponsor:", err);
      setToast({ message: "Failed to create sponsor. Please try again.", type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md max-w-lg mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-center">Create New Sponsor</h2>

      {toast && <ToastNotification message={toast.message} type={toast.type} onClose={() => setToast(null)} />}

      <form onSubmit={handleSubmit}>
        {/* Sponsor Name */}
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2" htmlFor="name">
            Sponsor Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className="w-full p-3 border border-gray-300 rounded-lg"
            required
          />
        </div>

        {/* Image Cropper */}
        <div className="mb-6">
          <button
            type="button"
            onClick={() => setShowCropper(true)}
            className="bg-blue-500 text-white p-3 rounded-lg w-full"
          >
            Add Images
          </button>
        </div>

        {showCropper && (
          <CropperSponsorImage
            onCropComplete={handleAddCroppedImage}
            onClose={() => setShowCropper(false)}
          />
        )}

        {/* Display Uploaded Images */}
        {formData.SponsorImages.length > 0 && (
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4">Uploaded Images</h3>
            <ul className="space-y-2">
              {formData.SponsorImages.map((image, index) => (
                <li key={index} className="flex items-center space-x-4">
                  <span className="text-sm font-medium">Tag: {image.Tags}</span>
                  <span className="text-sm text-gray-500">ID: {image.ImageId}</span>
                  <button onClick={() => handlePreviewImage(image.ImageId)} className="text-blue-500">Preview</button>
                  <button onClick={() => handleRemoveImage(image.ImageId)} className="text-red-500">Remove</button>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Submit Button */}
        <div className="mb-6 flex justify-center">
          <button type="submit" className="bg-[#2D1066] text-white p-3 rounded-lg w-full" disabled={loading}>
            {loading ? "Saving..." : "Save Sponsor"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateSponsor;
