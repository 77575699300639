import React, { useState, useEffect } from "react";
import { fetchSponsors, deleteSponsor, downloadFile } from "../api/api";
import Loader from "../components/Loader";
import { FaTrash } from "react-icons/fa"; // Import trashcan icon

const SponsorsList = () => {
  const [sponsors, setSponsors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sponsorImages, setSponsorImages] = useState({});
  const [deleting, setDeleting] = useState(null); // Track the sponsor being deleted

  useEffect(() => {
    const getSponsors = async () => {
      try {
        const sponsorData = await fetchSponsors();
        setSponsors(sponsorData);

        // Fetch sponsor images tagged as "RECTANGLE"
        const imagePromises = sponsorData.map(async (sponsor) => {
          if (sponsor.SponsorImages && sponsor.SponsorImages.length > 0) {
            const rectangleImage = sponsor.SponsorImages.find(
              (image) => image.Tags && image.Tags.toUpperCase() === "RECTANGLE"
            );

            if (rectangleImage && rectangleImage.ImageId) {
              try {
                const imageUrl = await downloadFile(rectangleImage.ImageId);
                return { SponsorId: sponsor.SponsorId, imageUrl };
              } catch {
                return { SponsorId: sponsor.SponsorId, imageUrl: null };
              }
            }
          }
          return { SponsorId: sponsor.SponsorId, imageUrl: null };
        });

        const images = await Promise.all(imagePromises);
        const imagesById = images.reduce((acc, item) => {
          acc[item.SponsorId] = item.imageUrl;
          return acc;
        }, {});
        setSponsorImages(imagesById);
      } catch (error) {
        setError("Failed to load sponsor details");
        console.error("Error fetching sponsor details:", error);
      } finally {
        setLoading(false);
      }
    };

    getSponsors();
  }, []);

  const handleDelete = async (sponsorId) => {
    if (!window.confirm("Are you sure you want to delete this sponsor?")) {
      return;
    }

    setDeleting(sponsorId); // Set the sponsor being deleted
    const token = localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth")).token
      : null;
    try {
      await deleteSponsor(sponsorId, token); // Call API to delete sponsor
      setSponsors((prevSponsors) =>
        prevSponsors.filter((sponsor) => sponsor.SponsorId !== sponsorId)
      ); // Remove deleted sponsor from state
    } catch (error) {
      console.error("Failed to delete sponsor:", error);
      alert("Failed to delete sponsor. Please try again.");
    } finally {
      setDeleting(null); // Clear the deleting state
    }
  };

  if (loading) return <Loader />;

  if (error) {
    return (
      <div className="text-center p-6">
        <p className="text-red-500 font-semibold">{error}</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg space-y-8">
      <h2 className="text-3xl font-bold text-center text-gray-800">
        Sponsor Details
      </h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {sponsors.map((sponsor) => (
          <div
            key={sponsor.SponsorId}
            className="bg-gray-50 p-4 rounded-lg shadow-md flex flex-col items-center relative hover:shadow-lg transition-shadow duration-300"
          >
            {/* Trashcan Icon */}
            <button
              className={`absolute top-2 right-2 bg-red-100 text-red-500 hover:bg-red-200 rounded-full p-2 shadow transition duration-200 ${
                deleting === sponsor.SponsorId
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              onClick={() => handleDelete(sponsor.SponsorId)}
              disabled={deleting === sponsor.SponsorId}
            >
              <FaTrash size={16} />
            </button>

            {/* Sponsor Image - Only if tagged as "RECTANGLE" */}
            <div className="flex items-center justify-center mb-4">
              {sponsorImages[sponsor.SponsorId] ? (
                <img
                  src={sponsorImages[sponsor.SponsorId]}
                  alt={sponsor.SponsorName}
                  className="w-32 h-20 rounded-md object-cover border border-gray-200 shadow-sm"
                />
              ) : (
                <div className="w-32 h-20 flex items-center justify-center bg-gray-200 text-gray-500 rounded-md">
                  No Image
                </div>
              )}
            </div>

            {/* Sponsor Details */}
            <h3 className="text-lg font-semibold text-gray-700 mb-1 text-center">
              {sponsor.SponsorName}
            </h3>
            <p className="text-sm text-gray-600 mb-2">{sponsor.SponsorType}</p>
            <div className="text-xs text-gray-500">
              <p>
                <strong>ID:</strong> {sponsor.SponsorId}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SponsorsList;
