// ToastNotification.js
import React from 'react';

const ToastNotification = ({ message, type, onClose }) => {
  return (
    <div
      className={`fixed bottom-5 right-5 bg-${type === 'success' ? 'green' : 'red'}-500 text-white py-2 px-4 rounded-lg shadow-lg`}
      style={{ zIndex: 9999 }}
    >
      <div className="flex items-center">
        <span>{message}</span>
        <button
          className="ml-3 text-xl"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default ToastNotification;
