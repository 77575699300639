import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import { useAuthContext } from '../AuthContext'; 

const Profile = () => {
  const { logout } = useAuthContext(); // Use the logout function from context
  const navigate = useNavigate(); // Use navigate to redirect

  const handleLogout = () => {
    console.log("Logging Out...");
    logout(); // Clear authentication data
    console.log("Redirecting to login page...");
    navigate('/login'); // Redirect to login page
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold">My Profile</h1>
      <button
        onClick={handleLogout} // Call handleLogout on button click
        className="mt-4 bg-red-500 text-white p-2 rounded"
      >
        Logout
      </button>
    </div>
  );
};

export default Profile;
